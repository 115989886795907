<template>
  <b-modal
    id="edit-agent-modal"
    ref="edit-agent-modal"
    modal-class="modal-primary"
    title="ข้อมูลตัวแทน"
    hide-footer
    size="lg"
  >
    <validation-observer ref="editAgentForm">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="ชื่อตัวแทน"
              rules=""
            >
              <b-form-group
                label="ชื่อตัวแทน"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="isRequestApproved"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="รายได้จากค่าธรรมเนียม"
              rules="between:1,100"
            >
              <b-form-group
                label="% รายได้จากค่าธรรมเนียม"
                label-for="commission"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="commission"
                    v-model="formData.commission"
                    :state="errors[0] ? false : null"
                    placeholder="1-100"
                  />

                  <b-input-group-append is-text>
                    %
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="เว็บไซต์ของเกม"
              rules=""
            >
              <b-form-group
                label="เว็บไซต์ของเกม"
                label-for="domain"
              >
                <b-form-input
                  id="domain"
                  v-model="formData.domain"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="อีเมลสำหรับเรียกเก็บเงิน"
              rules=""
            >
              <b-form-group
                label="อีเมลสำหรับเรียกเก็บเงิน"
                label-for="billingEmail"
              >
                <b-form-input
                  id="billingEmail"
                  v-model="formData.billingEmail"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="ชื่อผู้ติดต่อ"
              rules=""
            >
              <b-form-group
                label="ชื่อผู้ติดต่อ"
                label-for="contactName"
              >
                <b-form-input
                  id="contactName"
                  v-model="formData.contactName"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="อีเมลผู้ติดต่อ"
              rules=""
            >
              <b-form-group
                label="Email/ Telegram/ Line ผู้ติดต่อ"
                label-for="contactEmail"
              >
                <b-form-input
                  id="contactEmail"
                  v-model="formData.contactEmail"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <br>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ปิด
          </b-button>
          <b-overlay :show="isLoading">
            <b-button
              variant="primary"
              type="submit"
            >
              ยืนยัน
            </b-button>
          </b-overlay>
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="flat-danger"
            @click="onRemove"
          >
            <feather-icon icon="Trash2Icon" />
            ลบตัวแทน
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { between } from '@validations'

export default {
  name: 'EditAgentModal',
  props: {
    agentId: {
      type: String,
      required: true,
    },
    isRequestApproved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // validations
      between,

      formData: {
        email: '',
        domain: '',
        contactName: '',
        contactEmail: '',
        billingEmail: '',
        name: '',
        commission: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.agent.isEditingAgent,
      isEditSuccess: (state) => state.agent.isEditAgentSuccess,
      isRemoveSuccess: (state) => state.agent.isRemoveAgentSuccess,
    }),
    ...mapGetters(['requestDetail']),
  },
  watch: {
    requestDetail(val) {
      if (val) {
        this.setData()
      }
    },
    isEditSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
    isRemoveSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['editAgent', 'removeAgent']),
    setData() {
      if (this.requestDetail.id) {
        const data = JSON.parse(JSON.stringify(this.requestDetail))
        this.formData.id = this.agentId
        this.formData.email = data.email
        this.formData.name = data.agentName
        this.formData.contactName = data.contactName
        this.formData.contactEmail = data.contactEmail
        this.formData.billingEmail = data.billingEmail
        this.formData.domain = data.website
        this.formData.commission = data.commissionRate
      } else {
        this.formData = {
          email: '',
          domain: '',
          contactName: '',
          contactEmail: '',
          billingEmail: '',
          name: '',
          commission: 0,
        }
      }
    },
    hideModal() {
      this.$bvModal.hide('edit-agent-modal')
    },
    onSubmit() {
      this.$refs.editAgentForm.validate().then(async (success) => {
        if (success) {
          this.formData.commission = +this.formData.commission
          this.editAgent(this.formData)
          this.hideModal()
        }
      })
    },
    onRemove() {
      this.$bvModal
        .msgBoxConfirm(
          'เมื่อลบตัวแทนแล้ว ตัวแทนจะไม่สามารถเข้าถึง API และ Back office ได้อีก',
          {
            title: 'ยืนยันการลบตัวแทน',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'ลบตัวแทน',
            cancelTitle: 'ยกเลิก',
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.removeAgent(this.agentId)
            this.hideModal()
          }
        })
    },
  },
}
</script>
