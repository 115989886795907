var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"edit-agent-modal",attrs:{"id":"edit-agent-modal","modal-class":"modal-primary","title":"ข้อมูลตัวแทน","hide-footer":"","size":"lg"}},[_c('validation-observer',{ref:"editAgentForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"ชื่อตัวแทน","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ชื่อตัวแทน","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)]}}])})],1),(_vm.isRequestApproved)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"รายได้จากค่าธรรมเนียม","rules":"between:1,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"% รายได้จากค่าธรรมเนียม","label-for":"commission"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"commission","state":errors[0] ? false : null,"placeholder":"1-100"},model:{value:(_vm.formData.commission),callback:function ($$v) {_vm.$set(_vm.formData, "commission", $$v)},expression:"formData.commission"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" % ")])],1)],1)]}}],null,false,3205379795)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"เว็บไซต์ของเกม","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เว็บไซต์ของเกม","label-for":"domain"}},[_c('b-form-input',{attrs:{"id":"domain","state":errors[0] ? false : null},model:{value:(_vm.formData.domain),callback:function ($$v) {_vm.$set(_vm.formData, "domain", $$v)},expression:"formData.domain"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"อีเมลสำหรับเรียกเก็บเงิน","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"อีเมลสำหรับเรียกเก็บเงิน","label-for":"billingEmail"}},[_c('b-form-input',{attrs:{"id":"billingEmail","state":errors[0] ? false : null},model:{value:(_vm.formData.billingEmail),callback:function ($$v) {_vm.$set(_vm.formData, "billingEmail", $$v)},expression:"formData.billingEmail"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"ชื่อผู้ติดต่อ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ชื่อผู้ติดต่อ","label-for":"contactName"}},[_c('b-form-input',{attrs:{"id":"contactName","state":errors[0] ? false : null},model:{value:(_vm.formData.contactName),callback:function ($$v) {_vm.$set(_vm.formData, "contactName", $$v)},expression:"formData.contactName"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"อีเมลผู้ติดต่อ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email/ Telegram/ Line ผู้ติดต่อ","label-for":"contactEmail"}},[_c('b-form-input',{attrs:{"id":"contactEmail","state":errors[0] ? false : null},model:{value:(_vm.formData.contactEmail),callback:function ($$v) {_vm.$set(_vm.formData, "contactEmail", $$v)},expression:"formData.contactEmail"}})],1)]}}])})],1)],1),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":_vm.hideModal}},[_vm._v(" ปิด ")]),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" ยืนยัน ")])],1)],1),_c('div',{staticClass:"mt-2 d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"flat-danger"},on:{"click":_vm.onRemove}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" ลบตัวแทน ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }